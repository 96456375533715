import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { DotsComponents } from "../DotsComponent/DotsComponents";
import { BodyPartData } from "./BodyPartData";
import { BodyPiercingData } from "./BodyPiercingData";
import Arrow from "../../../../assets/images/arrow.png";
import {
  nextComponent,
  backComponent,
  setFormComponent,
  setFormComponentInfo,
  clearFormData,
  clearFormDataInfo,
} from "../../utils/nextComponent";
import { IconArrowRightAnimation } from "../Icons/Icon-arrow-right/IconArrowRightAnimation";

export const BodyPart = ({ block, props }) => {
  const { styles } = props;
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(true);
  const [body_part, setBodyPart] = useState("");
  const [body_part_site, setBodyPartSite] = useState("");
  const [subPart, setSubPart] = useState("");
  const [partSite, setPartSite] = useState("");
  const [body_partText, setBodyPartText] = useState("");
  const [body_part_siteText, setBodyPartSiteText] = useState("");
  const [partSiteArray, setPartSiteArray] = useState([]);
  const [parts, setParts] = useState([]);
  const [dot, setDot] = useState(0);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);
  const myRef4 = useRef(null);

  let bodyPartArray =
    props.user.id_rol === 4 ? BodyPiercingData() : BodyPartData();

  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
      props.setShowWriting(false);
      executeScroll();
    }, 1000);
    props.setShowWriting(true);
  }, []);

  ///////SCROLL GENERAL CON myRef2//////
  const executeScroll = () => {
    setTimeout(() => {
      myRef2?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  ///////////////////////////
  ////////SCROLL PARA PARTS DE TATTOO CON myRef3/////
  useEffect(() => {
    const executeScrollPartsTattoo = () => {
      myRef3?.current?.scrollIntoView({ behavior: "smooth" });
    };
    executeScrollPartsTattoo();
  }, [body_part]);
  //////////////////////

  //////////////////SCROOLL PARA SUBPARTS DE TTATOO CON myRef4///
  const executeScrollSubParts = () => {
    myRef4?.current?.scrollIntoView({ behavior: "smooth" });
  };
  ///////////////////////////////////////

  ///////SCROLL PARA PARTS DE PIERCING/////
  useEffect(() => {
    const executeScrollToFirstDiv = () => {
      const firstDiv = document.querySelector('[data-index="0"]');
      if (firstDiv) {
        firstDiv.scrollIntoView({ behavior: "smooth" });
      }
    };
    executeScrollToFirstDiv();
  }, [body_part_site]);
  /////////////////////////////////////////
  const handleBodyPart = (data, i) => {
    setSubPart("");
    props.setColor("");
    setBodyPart(data.value);
    setBodyPartSite(data.text);
    setBodyPartText(data.text);
    setFormComponent(props, block, { body_part: data.value });
    setFormComponentInfo(props, block, { body_part: data.text });
    backComponent(props, block);
    // clearFormData(props, block);
    // clearFormDataInfo(props, block);

    if (data.parts?.length > 0) {
      setParts(data.parts);
      // setSubPart("");
      setPartSiteArray([]);
    } else {
      setParts([]);
      setPartSiteArray([]);
      nextComponent(props, block);
    }
    setDot(i);
  };
  useEffect(() => {
    let ee = bodyPartArray.filter(
      (data) => data.id === body_part.toLowerCase()
    );

    setParts(ee[0]?.parts);

    if (subPart === "Media manga (muñeca a codo)") {
      setPartSiteArray(ee[0]?.parts[1]?.subParts);
    }
    if (subPart === "Media manga (codo a hombro)") {
      setPartSiteArray(ee[0]?.parts[2]?.subParts);
    }
    const bodyPartInfo = props.formDataInfo.filter((data) => data.body_part);
    let bodyPartTextFinally;
    if (bodyPartInfo[0]?.body_part?.split("-")[0]) {
      let partBoyPart = ee?.filter(
        (part) => part?.id === body_part?.toLowerCase() && part.text
      );
      bodyPartTextFinally = partBoyPart[0]?.text;
    }
    if (bodyPartInfo[0]?.body_part?.split("-")[1]) {
      const oo = ee[0]?.parts?.filter(
        (part) => part?.value === subPart ? part : null
      );
       if (oo !== undefined) {
        bodyPartTextFinally = bodyPartTextFinally + " - " + oo[0]?.text;
      }
    }
    if (bodyPartInfo[0]?.body_part?.split("-")[2]) {
      const oo = ee[0]?.parts[1]?.subParts.filter(
        (part) => part?.value === body_part_site && part
      );
      if (oo !== undefined) {
        bodyPartTextFinally = bodyPartTextFinally + " - " + oo[0]?.text;
      }
    }
    setFormComponentInfo(props, block, { body_part: bodyPartTextFinally });
  }, [props.language]);

  const handleBodySubPart = (value, text, subPart) => {
    setSubPart(value);
    setBodyPartSiteText(text);
    let block2 = {
      _uid: "BUY6Drn301",
      component: "body_part_site",
      headline: "body_part_site",
      conditional: true,
    };
    setFormComponent(props, block2, {
      body_part_site: value,
    });
    setFormComponentInfo(props, block, {
      body_part: body_partText + " - " + text,
    });
    backComponent(props, block);
    if (subPart !== undefined) {
      setPartSiteArray(subPart);
      setPartSite("");
      setTimeout(executeScrollSubParts, 0);
    } else {
      setTimeout(() => {
        nextComponent(props, block);
      }, 0);
      setPartSiteArray([]);
    }
  };

  const handleBodypartSite = (value, text) => {
    let block2 = {
      _uid: "BUY6Drn301",
      component: "body_part_site",
      headline: "body_part_site",
      conditional: true,
    };

    if (value) {
      setPartSite(value);
      setBodyPartSite(value);

      setFormComponent(props, block2, {
        body_part_site: subPart + " - " + value,
      });
      setFormComponentInfo(props, block, {
        body_part: body_partText + " - " + body_part_siteText + " - " + text,
      });
    }
    nextComponent(props, block);
  };

  return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <>
          <Form.Group id="">
            <div className="divGenericLabelFormChat" ref={myRef2}>
              <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
                {t("bodyPart.text1")}
                <span className="textBoldFormChat">{t("bodyPart.text2")}</span>
                {t("bodyPart.text3")}
              </Form.Label>
            </div>
          </Form.Group>
          <IconArrowRightAnimation />
          <Form.Group className="pFC">
            <div
              id="idBodyPartFormChat"
              className="divGenericBodyPartFormChat divUnsetScrollFormChat"
            >
              {bodyPartArray.map((data, index) => (
                <div key={data.id} className="divScrollBodypart">
                  <div key={data.id}>
                    <Form.Check type={data.type} id={`check-api-${data.id}`}>
                      <div
                        className="divBodyPartFormChat"
                        style={{
                          backgroundImage: styles
                            ? `linear-gradient(${styles?.color_filter}, ${styles?.color_filter}), url(${data.image})`
                            : `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${data.image})`,
                          backgroundColor: data?.image
                            ? null
                            : styles?.color_background_carrousel &&
                            styles?.color_background_carrousel,
                        }}
                      >
                        <Form.Check.Input
                          className="inputRadioFormChat"
                          type={data.type}
                          isValid
                          checked={body_part === data.value}
                          onChange={() => handleBodyPart(data, index)}
                        />
                        <Form.Check.Label className="divTextDefinitionLabelImage">
                          <div
                            className="divTextDefinitionImage"
                            style={{
                              position: "relative",
                            }}
                          >
                            {data.text === "Ingle" ||
                              data.text === "Glúteo" ||
                              data.text === "No lo sé" ||
                              data.text === "Otro" ? null : (
                              <img
                                src={Arrow}
                                alt="Flecha hacia abajo"
                                className="arrow-down"
                              />
                            )}
                          </div>
                        </Form.Check.Label>
                      </div>
                      <div className="divTextBodyPartPiercing">
                        <p
                          className={
                            props?.styles?.color_text_carousel !== null
                              ? "colorTextVarCarousel m-0"
                              : "colorTextVar m-0"
                          }
                        >
                          {data.text}
                        </p>
                      </div>
                    </Form.Check>
                  </div>
                </div>
              ))}
            </div>
          </Form.Group>
          <DotsComponents dotNumber={bodyPartArray.length} iDot={dot} />
          {props?.user.id_rol === 4 && parts?.length > 3 && (
            <IconArrowRightAnimation />
          )}
          <div
            className={
              subPart === undefined
                ? ""
                : props.user.id_rol !== 4
                  ? "div-parts-subParts pFC"
                  : "div-parts-subParts-reverse pFC"
            }
          >
            {body_part && props.user.id_rol !== 4 && (
              <div
                className="divGenericColumnInputFormChat mt-0 me-0"
                ref={myRef3}
              >
                <div
                  id="idBodyPart2FormChat"
                  className="divGenericInputTattooFormChat backgroundColorContainer"
                >
                  {parts?.map((data) => (
                    <Form.Check
                      key={data.id}
                      className="borderNoneFormChatSe"
                      type={data.type}
                      id={`check-api-${data.id}`}
                    >
                      <div
                        className={
                          "inputGenericFormChat" + " " + data?.classNameBorder
                        }
                        onClick={() =>
                          handleBodySubPart(
                            data.value,
                            data.text,
                            data?.subParts
                          )
                        }
                      >
                        <div className="divRadioBisFormChat">
                          <Form.Check.Input
                            className="inputRadioFormChat"
                            type={data.type}
                            isValid
                            readOnly
                            checked={subPart === data.value}
                          />
                          <Form.Check.Label className="divTextDefinitionLabel">
                            <p>{data.text}</p>
                          </Form.Check.Label>
                        </div>
                      </div>
                    </Form.Check>
                  ))}
                </div>
              </div>
            )}
            {body_part && props.user.id_rol === 4 && (
              <div className="divGenericColumnInputFormChatPiercing mt-0">
                <div
                  id="idBodyPartFormChat"
                  className="divGenericBodyPartFormChat divUnsetScrollFormChat"
                >
                  {parts?.map((data, index) => (
                    <div
                      key={data.id}
                      className="divScrollBodypart"
                      data-index={index}
                    >
                      <div key={data.id}>
                        <Form.Check
                          type={data.type}
                          id={`check-api-${data.id}`}
                        >
                          <div
                            // className={
                            //   data?.image
                            //     ? "divBodyPartFormChat styles-images-body-piercing"
                            //     : "backgroundCarrousel styles-images-body-piercing divBodyPartFormChat"
                            // }
                            className="styles-images-body-piercing divBodyPartFormChat"
                            style={{
                              backgroundImage: styles
                                ? `linear-gradient(${styles?.color_filter}, ${styles?.color_filter}), url(${data.image})`
                                : `  url(${data.image})`,
                              borderRadius: "11px",
                              backgroundColor: data?.image
                                ? null
                                : styles?.color_background_carrousel &&
                                styles?.color_background_carrousel,
                            }}
                          >
                            <Form.Check.Input
                              className="inputRadioFormChat"
                              type={data.type}
                              isValid
                              checked={subPart === data.value}
                              onChange={() =>
                                handleBodySubPart(
                                  data.value,
                                  data.text,
                                  data?.subParts
                                )
                              }
                            />
                            <Form.Check.Label className="divTextDefinitionLabelImage"></Form.Check.Label>
                          </div>
                          <div className="divTextBodyPartPiercing">
                            <p className="colorTextVar m-0">{data.text}</p>
                          </div>
                        </Form.Check>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {subPart.length !== 0 && props.user.id_rol !== 4 && (
              <div
                className={subPart === undefined ? "" : "div-subParts"}
                ref={myRef4}
              >
                <div
                  id="idBodyPart2FormChat"
                  className="divGenericInputTattooFormChat div-subParts-checks"
                >
                  {partSiteArray?.map((data) => (
                    <Form.Check
                      key={data.id}
                      className="borderNoneFormChatSe"
                      type={data.type}
                      id={`check-api-${data.id}`}
                    >
                      <div
                        className="inputGenericFormChat"
                        onClick={() =>
                          handleBodypartSite(data.value, data.text)
                        }
                      >
                        <div className="divRadioBisFormChat">
                          <Form.Check.Input
                            className="inputRadioFormChat"
                            type={data.type}
                            isValid
                            readOnly
                            checked={partSite === data.value}
                          // onChange={() =>
                          //   handleBodypartSite(data.value, data.text)
                          // }
                          />
                          <Form.Check.Label className="divTextDefinitionLabel">
                            <p>{data.text}</p>
                          </Form.Check.Label>
                        </div>
                      </div>
                    </Form.Check>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
