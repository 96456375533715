import React from "react";
import { Button } from "react-bootstrap";

export const ButtonNewBudget = ({ buttonClass, handle, text }) => {
  return (
    <Button onClick={handle} className={buttonClass}>
      {text}
    </Button>
  );
};
