import React from "react";
import { Row, Col } from "react-bootstrap";

export const Logo = ({ user, medias, styles }) => {
  let initialName = user.studio_name;
  let resultInitialName = initialName?.charAt(0).toUpperCase();
  const urlImage = "https://my.inkup.io/imagesStudio/" + styles?.logo;

  return (
    <Row>
      <Col>
      {user.vip === 6 && (styles?.logo !== null && styles?.logo !== undefined) ? (
          <div className="circle-logo">
            <img
              className="imageAvatarLogoFormTemplate logo5"
              src={urlImage}
              alt=""
            ></img>
          </div>
        ) : (
          <>
            {medias ? (
              <div className="avatar">
                <img
                  className="imageAvatarLogoFormTemplate"
                  src={medias?.path_media}
                  alt=""
                ></img>
              </div>
            ) : (
              <div className="logoInitialFormTemplate">
                <p className="textlogoInitialFormTemplate">
                  {resultInitialName}
                </p>
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};
