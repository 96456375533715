import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconWhatsApp } from "../Icons/Icon-WhatsApp/IconWhatsApp";
import iconEmail from "../../../../assets/images/Icon-Email.svg";
import { WhatsappMessage } from "../../../utils/whatsappMessage";
import { mailMessage } from "../../../utils/mailMessage";

export const ButtonInkup = ({ disabled, disabledStatus, props }) => {
  const [t] = useTranslation("global");
  let text1BottomFormChat = `${t("SendWhatsAppForm.buttonSendForWhatsApp")}`;
  let text3BottomFormChat = `${t("info.buttonSendEmail")}`;
  let text4BottomFormchat = `${t("info.buttonSendRequest")}`;
  let phone;
  let textW;

  const handleSendMessage = () => {
    props.setSendSuccess(true);
    if (props.budgetCount) {
      if (props.contact === null) {
         props.user.check_tattoo === 1 &&
          WhatsappMessage(
            props.phoneCity ? props.phoneCity : props.user.phone,
            phone,
            props.formDataInfo,
            textW,
            props.user,
            t,
            props.optionButtonServices
          );

        props.user.check_tattoo === 0 &&
          mailMessage(
            props.user.email,
            props.formDataInfo,
            textW,
            props.formData,
            props.user,
            t,
            props.optionButtonServices
          );
      }

      if (props.contact === 0) {
 
        WhatsappMessage(
          props.phoneCity ? props.phoneCity : props.user.phone,
          phone,
          props.formDataInfo,
          textW,
          props.user,
          t,
          props.optionButtonServices
        );
      } else if (props.contact === 1) {
        mailMessage(
          props.emailCity,
          props.formDataInfo,
          textW,
          props.formData,
          props.user,
          t,
          props.optionButtonServices
        );
      }
    }
  };

  return (
    <Button
      onClick={() => handleSendMessage()}
      className="botonFormTemplate2"
      disabled={!disabled || disabledStatus === null}
    >
      {props.budgetCount === true ? (
        <>
          {props?.contact === null
            ? props?.user?.check_tattoo === 1 && (
                <>
                  {text1BottomFormChat}
                  <IconWhatsApp />
                </>
              )
            : props?.contact === 0 && (
                <>
                  {text1BottomFormChat}
                  <IconWhatsApp />
                </>
              )}
          {props?.contact === null
            ? props.user.check_tattoo === 0 && (
                <>
                  {text3BottomFormChat}
                  <img className="iconW" src={iconEmail} alt="icon-w" />
                </>
              )
            : props?.contact === 1 && (
                <>
                  {text3BottomFormChat}
                  <img className="iconW" src={iconEmail} alt="icon-w" />
                </>
              )}
        </>
      ) : (
        <>{text4BottomFormchat} </>
      )}
    </Button>
  );
};
