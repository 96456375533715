import axios from "axios";
import { backComponent2, resetFormData, resetNextComponent } from "../components/FormChat/utils/nextComponent";

const ROOT_URL = process.env.REACT_APP_API_URL;

export const studioUser = async (studioName) => {
  try {
    const response = await axios.post(`${ROOT_URL}/api/user/getAssistant`, {
      studioName,
    });
    return response;
  } catch (error) {
    return false;
  }
};
export const saveBudget = async ({ id_user, formData, budget_type }) => {
  formData.push({ id_user });
  formData.push({ budget_type });
  try {
    const result = await axios.post(`${ROOT_URL}/api/budget/saveBudget`, { formData });
    return result
  }
  catch (error) {
    return false;
  }
};

export const saveBudget2 = async ({ id_user, formData, budget_type, setErrorAlert, arrayComponentInitial, setStatus,
  arrayComponent,
  setComponents, setCheckTerms }, getData, history, text) => {

  formData.push({ id_user });

  formData.push({ budget_type });


  const arrayKeys = [];
  const formDataWithoutImages = formData.filter(item => !("images" in item));

  formDataWithoutImages.forEach(objeto => {
    Object.entries(objeto).forEach(([clave, valor]) => {
      arrayKeys.push(`${clave}--${valor}`);
    });
  });
  try {
    const result = await axios.post(`${ROOT_URL}/api/budget/saveBudget`, { formData: formData }, {
      timeout: 8000 // Tiempo límite de 5 segundos (en milisegundos)
    });



    if (result.data.result === 500) {
      const res = await axios.get("https://api.ipify.org/?format=json");
      try {

        const result = await axios.post(`${ROOT_URL}/api/budget/saveBudget/reloaded`, { formData: formData, arrayKeys, ip: res.data.ip }, {
          timeout: 8000 // Tiempo límite de 5 segundos (en milisegundos)
        });

        setStatus(null)

        if (result.data.result === 500) {
          resetNextComponent(arrayComponentInitial, arrayComponent, setComponents)
          getData()
          setErrorAlert(true)
          setStatus(null)
          history('/reload/' + text + '/' + arrayKeys)
        } else {
          setStatus(result.data.result)
          return result
        }
      } catch (error) {
        if (error.code === 'ECONNABORTED') {
          resetNextComponent(arrayComponentInitial, arrayComponent, setComponents)
          getData()
          setErrorAlert(true)
          setStatus(null)
          setCheckTerms(false)
          history('/reload/' + text + '/' + arrayKeys)
        }

      } finally {
        console.log('  FINALLY3');

      }

    } else {

      setStatus(result.data.result)
      return result
    }

  } catch (error) {
    if (error.code === 'ECONNABORTED') {
      resetNextComponent(arrayComponentInitial, arrayComponent, setComponents)
      getData()
      setErrorAlert(true)
      setStatus(null)
      setCheckTerms(false)
      history('/reload/' + text + '/' + arrayKeys)
    }
  } finally {
    console.log('  FINALLY2');

  }
};

///////RUTA PARA GUARDAR OTRAS CONSULTAS
export const saveAnotherQuery = async ({ id_user, query }) => {
  try {
    return await axios.post(`${ROOT_URL}/api/user/saveAnotherQuery`, {
      id_user,
      query,
    });
  } catch (error) {
    return false;
  }
};

export const sendEmailForm = async ({
  emailClient,
  phoneClient,
  emailOwner,
  text,
  responseToClientText,
  subject,
  subjetEmailResend,
  resendToMessage,
}) => {
  try {
    return await axios.post(`${ROOT_URL}/api/budget/sendEmailForm`, {
      emailClient,
      phoneClient,
      emailOwner,
      text,
      responseToClientText,
      subject,
      subjetEmailResend,
      resendToMessage,
    });
  } catch (error) {
    return false;
  }
};

export const saveChatGpt = async (id_user, query) => {
  try {
    const response = await axios.post(`${ROOT_URL}/api/user/saveChatGpt`, {
      id_user,
      query,
    });
    if (response.status === 200) {
      return response.data.insertId;
    }
  } catch (error) {
    return false;
  }
};
export const updateChatGpt = async (id, query) => {
  try {
    await axios.put(`${ROOT_URL}/api/user/updateChatGpt`, {
      id,
      query,
    });
  } catch (error) {
    return false;
  }
};
