import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import {
  backComponent,
  clearFormData,
  nextComponent,
} from "../../utils/nextComponent";
import { EmailOrWhatsappData } from "./EmaiOrWhatsappData";
import { Email } from "../Email/Email";
import { Phone } from "../Phone/Phone";

export const EmailOrWhatsapp = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(true);
  const myRef2 = useRef(null);
  const newEmailOrWhatsappData = EmailOrWhatsappData();
  let showAll =
    (props.phoneCity && props.emailCity) ||
    (props.phoneCity === null && props.emailCity === null);

  let showPhone = props.phoneCity === null && props.emailCity;
  let showEmail = props.phoneCity && props.emailCity === null;

  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
      executeScroll();
    }, 1000);
  }, []);

  useEffect(() => {
    if (props.contact === 0 && props.typeArray === 1) {
      backComponent(props, block, 1);
      clearFormData(props, block);
      // nextComponent(props, block, 1);
      nextComponent(props, block);
    }
    if (props.contact === 1 && props.typeArray === 1) {
      backComponent(props, block, 1);
      clearFormData(props, block);
    }
  }, [props.contact]);

  const executeScroll = () => {
    setTimeout(() => {
      myRef2?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleChange = (data) => {
    props.setContact(data.name);
    // if (props.arrayComponentServices.length !== 0) {
    if (props.contact === false) {
      setTimeout(() => {
        props.setShowWriting(false);
      }, 1000);
      props.setShowWriting(true);
    }

    // if (props.typeArray === 1 && props.contact === 0) {
    //   nextComponent(props, block, 1);
    // }

    if (props.typeArray === 0) {
      backComponent(props, block);
    }
    // } else {
    //   nextComponent(props, block);
    // }
  };

  return (
    <>
      {showAll ? (
        messageLoading === true ? (
          <MessageLoading imgLogoInitial={props.initailOrImage} />
        ) : (
          <>
            <Form.Group id="">
              <div className="divGenericLabelFormChat" ref={myRef2}>
                <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer notMargin">
                  <span className="textBoldFormChat">
                    {t("emailOrWhatsapp.copy")}
                  </span>
                </Form.Label>
              </div>
            </Form.Group>
            <div className="divGenericColumnInputFormChat">
              <div
                id="idEMailOrWhatsapp"
                className="divGenericInputTattooFormChat div-zindex"
              >
                {newEmailOrWhatsappData?.map((data, index) => (
                  <Form.Check
                    key={index}
                    className="borderNoneFormChatSe"
                    type="radio"
                    id={`check-api-${data.id}`}
                  >
                    <div
                      className="inputGenericFormChat"
                      onClick={() => handleChange(data)}
                    >
                      <div className="">
                        <div className="divRadioBisFormChat">
                          <Form.Check.Input
                            className="inputRadioFormChat"
                            type="radio"
                            isValid
                            readOnly
                            checked={props.contact === data.name}
                            // onChange={() => handleChange(data)}
                          />
                          <Form.Check.Label className="divTextDefinitionLabel">
                            <p className="fisrtCapitalize">{data.text}</p>
                          </Form.Check.Label>
                        </div>
                      </div>
                    </div>
                  </Form.Check>
                ))}
              </div>
            </div>
            {props.contact === 1 && <Email props={props} block={block} />}
            {/* {props.contact === 0 && props.typeArray === 0 && ( */}
            {props.contact === 0 && <Phone block={block} props={props} />}
          </>
        )
      ) : showPhone ? (
        <Email props={props} block={block} />
      ) : showEmail ? (
        <Phone block={block} props={props} />
      ) : null}
    </>
  );
};
