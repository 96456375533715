import { mailMessage } from "../../../utils/mailMessage";
import { WhatsappMessage } from "../../../utils/whatsappMessage";

export const servicesTextMessageResponse = (option, props, t) => {
  props.setSendSuccess(true);
  let textW;
  let phoneServices;
  let userPhone = props.user.phone;
  let emailCity =
    option === "academy" ? props.user.mail_academy : props.emailCity;

  if (option === "laser") {
    phoneServices = props.user.phone_laser || props.phoneCity || userPhone;

    textW = `${t("service.sendWhatsAppHello")} ${
      props.user.studio_name
    }.%0a%0a${t("service.sendWhatsAppTextLaser")}
    ${
      (props.contact === null && props.user.check_tattoo === 1) ||
      props.contact === 0
        ? `%0a%0a${t("service.sendWhatsAppTextLaser1")}%0a`
        : ""
    }`;
  }

  if (option === "micro") {
    phoneServices = props.user.phone_micro || props.phoneCity || userPhone;

    textW = `${t("service.sendWhatsAppHello")} ${
      props.user.studio_name
    }.%0a%0a${t("service.sendWhatsAppTextMicro")}`;
  }

  if (option === "piercing") {

    phoneServices = props.user.phone_piercing || props.phoneCity || userPhone;

    textW = `${t("service.sendWhatsAppHello")} ${
      props.user.studio_name
    }.%0a%0a${t("service.sendWhatsAppTextPiercing")}`;
  }

  if (option === "barber") {
    phoneServices = props.user.phone_barber || props.phoneCity || userPhone;

    textW = `${t("service.sendWhatsAppHello")} ${
      props.user.studio_name
    }.%0a%0a${t("service.sendWhatsAppTextBarber")}`;
  }

  if (option === "microblading") {
    phoneServices =
      props.user.phone_microblading || props.phoneCity || userPhone;

    textW = `${t("service.sendWhatsAppHello")} ${
      props.user.studio_name
    }.%0a%0a${t("service.sendWhatsAppTextMicroblading")}`;
  }

  if (option === "gem_tooth") {

    phoneServices = props.user.phone_gem_tooth || props.phoneCity || userPhone;

    textW = `${t("service.sendWhatsAppHello")} ${
      props.user.studio_name
    }.%0a%0a${t("service.sendWhatsAppTextGemTooth")}`;
  }

  if (option === "academy") {
    phoneServices = props.user.phone_academy || props.phoneCity || userPhone;

    textW = `${t("service.sendWhatsAppHello")} ${
      props.user.studio_name
    }.%0a%0a${t("service.sendWhatsAppTextAcademy")}`;
  }

  if (option === "anotherQuery") {
    phoneServices =
      props.user.id_user === 1499 || props.phoneCity
        ? props.phoneCity
        : userPhone;
    textW = `(Inkup)%0a${props.query}`;
  }

  if (props.budgetCount) {
    if (props.contact === null) {
      props.user.check_tattoo === 1 &&

        WhatsappMessage(
          props.user.phone,
          phoneServices,
          props.formDataInfo,
          textW,
          props.user,
          t,
          props.optionButtonServices
        );
      props.user.check_tattoo === 0 &&
        mailMessage(
          props.user.email,
          props.formDataInfo,
          textW,
          props.formData,
          props.user,
          t,
          props.optionButtonServices
        );
    }
    //cuando tiene en la tabla city los campos rellenos

    if (props.contact === 0) {
      WhatsappMessage(
        phoneServices,
        phoneServices,
        // option === "academy" ? props.user.phone_academy : props.phoneCity,
        props.formDataInfo,
        textW,
        props.user,
        t,
        props.optionButtonServices
      );
    } else if (props.contact === 1) {
      mailMessage(
        emailCity,
        props.formDataInfo,
        textW,
        props.formData,
        props.user,
        t,
        props.optionButtonServices
      );
    }
  }
};
