import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { Form, Button } from "react-bootstrap";
import { IconArrowRight } from "../Icons/Icon-arrow-right/IconArrowRight";
import {
  nextComponent,
  saveChanges,
  backComponent,
  setFormComponent,
  setFormComponentInfo,
  clearFormData,
} from "../../utils/nextComponent";

export const Description = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(null);
  const [textAreaCount, changeTextAreaCount] = useState(0);
  const [description, setDescription] = useState("");
  const [button, setButton] = useState(true);
  const myRef2 = useRef(null);

  const images = props?.formData?.filter((images) => {
    return images["images"];
  });

  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
      props.setShowWriting(false);
      executeScroll();
    }, 1000);
    props.setShowWriting(true);
    setMessageLoading(true);
  }, []);

  useEffect(() => {
    const images = props?.formData?.filter((images) => {
      return images["images"];
    });

    if (
      (images[0]?.images?.length === 0 ||
        images[0]?.images?.length === undefined) &&
      description <= 3
    ) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [images[0]?.images?.length]);

  const handleChange = (e) => {
    const { value } = e.target;
    changeTextAreaCount(value.length);
    setDescription(value);
    setButton(false);
    saveChanges(props, block);
    setFormComponent(props, block, { description: value });
    setFormComponentInfo(props, block, { description: value });

    if (
      value.length <= 3 &&
      (images[0]?.images?.length === 0 ||
        images[0]?.images?.length === undefined)
    ) {
      backComponent(props, block);
      clearFormData(props, block, { description: value });
      setButton(true);
    }
  };

  const executeScroll = () => {
    setTimeout(() => {
      myRef2?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const next = () => {
    if (description !== "") {
      setFormComponent(props, block, { description });
      setFormComponentInfo(props, block, { description });
    }
    nextComponent(props, block);
    setButton(true);
  };

  return (
    <>
      {messageLoading === true && <MessageLoading />}
      {messageLoading === false && (
        <>
          <Form.Group className="mt-4">
            <div
              className="divGenericLabelFormChat marginNoneLabelFormChat"
              ref={myRef2}
            >
              <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
                <span className="textBoldFormChat">
                  {t("description.text2")}
                </span>
                {t("description.text3")}
              </Form.Label>
            </div>
          </Form.Group>
          <Form.Group>
            <div className="divDescriptionFormChat">
              <Form.Control
                id="idTextAreaDescriptionFormChat"
                className="inputTextarea2 sizeTextareaFormChat urbanist"
                as="textarea"
                maxLength={1000}
                placeholder={t("description.textArea")}
                onChange={handleChange}
                required
                autoFocus={true}
              />
              <p className="text-secondary numbersTextareaFormChat">
                {textAreaCount}/1000
              </p>
            </div>
          </Form.Group>
          <div className="divButtonSendImageImagesTatto">
            <Button
              className="buttonSendImageImagesTatto2"
              disabled={button}
              onClick={() => next()}
            >
              {t("description.continue")}
              <IconArrowRight />
            </Button>
          </div>
        </>
      )}
    </>
  );
};
