export const EmailOrWhatsappData = () => {
  return [
    {
      id: "whatsapp",
      name: 0,
      text: "WhatsApp",
    },
    {
      id: "email",
      name: 1,
      text: "Email",
    },
  ];
};
