import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { IconCheckGreen } from "../Icons/Icon-check-green/IconCheckGreen";
import { IconInfo } from "../Icons/Icon-info/IconInfo ";
import {
  nextComponent,
  backComponent,
  setFormComponent,
  clearFormData,
} from "../../utils/nextComponent";

export const Email = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(true);
  const [email_budget, setEmail] = useState("");
  const [validMail, setValidMail] = useState("divTextFormChat");
  const [email, setEmail2] = useState('');

  const myRef2 = useRef(null);
  const block2 = {
    _uid: "BUY6Drn1e3",
    component: "email_budget",
    headline: "email_budget",
    conditional: true,
  };

  useEffect(() => {
    if (block.conditional) {
      props.setShowWriting(true);
      setTimeout(() => {
        setMessageLoading(false);
        props.setShowWriting(false);
        if (
          props.user.check_tattoo === 1 ||
          props.optionButtonServices === "tatuaje"
        ) {
          executeScroll();
        }
        if (
          props.arrayComponentServices !== 0 &&
          props.optionButtonServices !== "tatuaje"
        ) {
          executeScroll();
        }
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (validMail === "divTextFormChatGreen") {
      // props.setDisabledTextAreaAnotherQuery(false);
      setFormComponent(props, block2, { email_budget });
      if (
        props.optionButtonServices === "tatuaje" ||
        props.optionButtonServices === null
      ) {
        nextComponent(props, block);
      } else if (props.arrayComponentServices.length === 0) {
        executeScroll();
        props.setDisabledButtonSendEmail(false);
      }
    } else if (
      props.optionButtonServices !== "tatuaje" &&
      validMail === "divTextFormChatRed" &&
      props.arrayComponentServices.length === 0
    ) {
      props.setDisabledButtonSendEmail(true);
      if (props.user.check_tattoo === 0) {
        props.setCheckTermsAnotherQuery(false);
      }
    }

    if (
      props.arrayComponentServices.length !== 0 &&
      validMail === "divTextFormChatGreen" &&
      props.optionButtonServices !== "tatuaje"
    ) {
      nextComponent(props, block, 1);
    }
  }, [validMail, email_budget]);

  const handleChange = (e) => {
    setEmail(e.target.value);
    let emailInput = e.target.value;

    if (emailInput.includes("@")) {
      let resultEmail = emailInput.split("@");
      if (resultEmail[0] !== "" && resultEmail[1].includes(".")) {
        let resultEmail1 = resultEmail[1].split(".");
        if (
          resultEmail1[0] !== "" &&
          resultEmail1[1].length >= 2 &&
          resultEmail1[1].length <= 7
        ) {
          setValidMail("divTextFormChatGreen");
          props.setDisabledTextAreaAnotherQuery(false);
        } else {
          props.setDisabledTextAreaAnotherQuery(true);
          setValidMail("divTextFormChatRed");
          backComponent(props, block, 1);
          backComponent(props, block);
          // clearFormData(props, block, { email_budget });
        }
      }
    }

    if (emailInput === "" || emailInput.length === 1) {
      setValidMail("divTextFormChatRed");
      props.setDisabledTextAreaAnotherQuery(true);
      if (props.typeArray === 0) {
        backComponent(props, block);
        // clearFormData(props, block, { email_budget });
      }
      if (props.typeArray === 1) {
        backComponent(props, block, 1);
        clearFormData(props, block, { email_budget }, 1);
      }
    }
  };

  const executeScroll = () => {
    setTimeout(() => {
      myRef2?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <Form.Group id="nombre" className="">
          <div className="divGenericLabelFormChatWelcome" ref={myRef2}>
            <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
              {t("email.email2")}
              <span className="textBoldFormChat">{t("email.email3")} </span>
              {t("email.email4")}
            </Form.Label>
          </div>
          <label htmlFor="email">Correo electrónico:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={handleChange}
        placeholder="example@domain.com"
        required
      />
          <div className="divGenericInputFormChat">
            <div className="divInputFormChat">
              <Form.Control
                autoComplete="nope"
                id="inputNameFormChat"
                className="inputNameFormChat inputEmailFormChat"
                type="email"
                placeholder={"Email"}
                onChange={handleChange}
                required
                autoFocus={true}
              />
            </div>
            
          </div>
          <div className="divGenericInputFormChat me-0">
            <div className="divGenericInputFormChat">
              <div
                className={
                  (validMail === "divTextFormChat" && "divTextFormChat") ||
                  (validMail === "divTextFormChatRed" &&
                    "divTextFormChatRed") ||
                  (validMail === "divTextFormChatGreen" &&
                    "divTextFormChatGreenBis")
                }
              >
                {validMail === "divTextFormChat" ||
                  validMail === "divTextFormChatRed" ? (
                  <>
                    <IconInfo />
                    <p className="marginTextPhone colorSpantVar">
                      {t("email.exampleAbreviation")}antonio@gmail.com
                    </p>
                  </>
                ) : (
                  <>
                    <IconCheckGreen />
                    <p className={`${validMail} marginTextEmail`}>
                      {t("email.valid")}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </Form.Group>
      )}
    </>
  );
};
