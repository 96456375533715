import { textMessage } from "./textMessage/textMessage";
import { sendEmailForm } from "../../helpers/helpers";
import { responseToClientMessage } from "./textMessage/responseToClientTextMessage";

export const mailMessage = (
  emailCity,
  formDataInfo,
  textW,
  formData,
  user,
  t,
  optionButtonServices
) => {
  const { email_budget: emailClient } = formData.find(
    (obj) => obj.email_budget
  );

  const foundObject = formData.find((obj) => obj.phone_budget !== undefined);
  const clientPhone = foundObject ? foundObject.phone_budget : undefined;
  let phoneClient;

  if (clientPhone !== undefined) {
    phoneClient = `<strong>${t(
      "SendWhatsAppForm.phone"
    )}</strong>+${clientPhone}`;
  }

  let text;
  let textResponseToClient;
  let sendMessageMailServices = textW?.replace(/&/g, "%26");

  if (optionButtonServices === "tatuaje" || optionButtonServices === null) {
    text = textMessage(formDataInfo, user, t);
    textResponseToClient = responseToClientMessage(
      formDataInfo,
      user,
      t,
      false
    );
  } else {
    text = sendMessageMailServices;
  }

  const emailOwner = emailCity === null ? user.email : emailCity;
  const text2 = text.replace(/&/g, "%26");
  const responseToClientText = textResponseToClient?.replace(/&/g, "%26");
  const subject = `${t("emailBudget.emailSubject")}`;
  const subjetEmailResend = `${t("emailBudget.emailResendSubject")}`;
  const resendToMessage = `${t("emailBudget.resendToMessage")}`;
   sendEmailForm({
    emailClient: emailClient,
    phoneClient: phoneClient,
    emailOwner: emailOwner,
    text: text2,
    responseToClientText: responseToClientText,
    subject: subject,
    subjetEmailResend: subjetEmailResend,
    resendToMessage: resendToMessage,
  });
};
