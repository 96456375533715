import { useTranslation } from "react-i18next";

export const ServicesData = (
  check_query,
  check_laser,
  check_micro,
  check_piercing,
  check_barber,
  check_microblading,
  check_gem_tooth,
  id_rol,
  check_academy
) => {
  const [t] = useTranslation("global");

  return [
    {
      id: "tatuaje",
      text: id_rol !== 4 ? `${t("serviceData.textTattoo")}` : "Piercing",
      value: true,
      type: "radio",
      checkData: true,
    },
    {
      id: "piercing",
      text: "Piercing",
      value: false,
      type: "radio",
      checkData: check_piercing === 1 ? true : false,
    },
    {
      id: "academy",
      text: `${t("serviceData.textAcademy")}`,
      value: false,
      type: "radio",
      checkData: check_academy === 1 ? true : false,
    },
    {
      id: "laser",
      text: `${t("serviceData.textDeleteLaser")}`,
      value: false,
      type: "radio",
      checkData: check_laser === 1 ? true : false,
    },
    {
      id: "micro",
      text: `${t("serviceData.textMicro")}`,
      value: false,
      type: "radio",
      checkData: check_micro === 1 ? true : false,
    },
    {
      id: "barber",
      text: `${t("serviceData.textBarber")}`,
      value: false,
      type: "radio",
      checkData: check_barber === 1 ? true : false,
    },
    {
      id: "microblading",
      text: `${t("serviceData.textMicroblading")}`,
      value: false,
      type: "radio",
      checkData: check_microblading === 1 ? true : false,
    },
    {
      id: "gem_tooth",
      text: `${t("serviceData.textGemTooth")}`,
      value: false,
      type: "radio",
      checkData: check_gem_tooth === 1 ? true : false,
    },
    {
      id: "anotherQuery",
      text: `${t("serviceData.anotherQuery")}`,
      value: false,
      type: "radio",
      checkData: check_query === 1 ? true : false,
    },
  ];
};
