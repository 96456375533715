export const initailOrImage = (user, medias, styles) => {
  let imgLogoFormChat;

  if (user.vip === 6 && styles.logo) {
    const urlImage = "https://my.inkup.io/imagesStudio/" + styles?.logo;
    imgLogoFormChat = urlImage;
  } else {
    if (medias) {
      imgLogoFormChat = medias?.path_media;
    } else {
      let initialName = user.studio_name;
      let resultInitialName = initialName?.charAt(0).toUpperCase();
      imgLogoFormChat = resultInitialName;
    }
  }
  return imgLogoFormChat;
};
