import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconWhatsApp } from "../Icons/Icon-WhatsApp/IconWhatsApp";
import iconEmail from "../../../../assets/images/Icon-Email.svg";
import { servicesTextMessageResponse } from "../Services/servicesTextMessageResponse";

export const ButtonAnotherQuery = ({ props }) => {
  const [t] = useTranslation("global");
  let text1BottomFormChat = `${t("SendWhatsAppForm.buttonSendForWhatsApp")}`;
  let text3BottomFormChat = `${t("info.buttonSendEmail")}`;
  let text4BottomFormchat = `${t("info.buttonSendRequest")}`;
  // let phone = props.user.phone;
  // let checkTattoo = props.user.check_tattoo;
  // let email = props.user.email;
  let optionButton = props.optionButtonServices;

  // const sendMessage = () => {
  //   props.setSendSuccess(true)
  //   if (checkTattoo === 1) {
  //     let whatsapp = `https://api.whatsapp.com/send?phone=+${phone}&text=${query}`;
  //     window.open(`${whatsapp}`, "_blank");
  //   } else {
  //     const text = query;
  //     const text2 = text.replace(/&/g, "%26");
  //     let mail =
  //       "mailto:" +
  //       email +
  //       `?subject=${t("emailBudget.emailSubject")}&body=` +
  //       text2;
  //     window.open(`${mail}`, "_blank");
  //   }
  // };
  return (
    <Button
      // onClick={sendMessage}
      onClick={() => servicesTextMessageResponse(optionButton, props, t)}
      className="botonFormTemplate2"
      disabled={
        props.user.check_tattoo === 0
          ? !props.checkTermsAnotherQuery
          : !props.checkTermsAnotherQuery
      }
    >
      {props.budgetCount === true ? (
        <>
          {props?.user?.check_tattoo === 1 && (
            <>
              {text1BottomFormChat}
              <IconWhatsApp />
            </>
          )}
          {props.user.check_tattoo === 0 && (
            <>
              {text3BottomFormChat}
              <img className="iconW" src={iconEmail} alt="icon-w" />
            </>
          )}
        </>
      ) : (
        <>{text4BottomFormchat} </>
      )}
    </Button>
  );
};
