import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import imageLogoType from "../../assets/images/Logotype.svg";
import { ModalFooter } from "./ModalFooter";

export const Footer = ({ props }) => {
  const [t] = useTranslation("global");
  const [show, setShow] = useState(false);
  const domainName = props?.user?.domain_name
  return (
    <>
      <ModalFooter show={show} handleClose={() => setShow(false)} domainName={domainName}></ModalFooter>
      <Row>
        <Col>
          <div className="divFooterForChat" onClick={() => setShow(true)}>
            <Button className="button-footer">{t("footer.text")}</Button>
            <div className="divImgLogoType">
              <img src={imageLogoType} alt=""></img>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
