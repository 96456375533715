//oo = 0 TATUAJE
//oo = 1 SERVICIOS

export const nextComponent = (props, block, oo) => {
  let array =
    oo === 0 || oo === undefined
      ? props.arrayComponentInitial
      : props.arrayComponentServices;
  if (array.length === 0) {
    props.arrayComponent.filter((data, idx) => {
      if (data._uid === block._uid) {
        props.setComponents([
          ...props.arrayComponent,
          { ...(props.arrayComponent[idx + 1].conditional = true) },
        ]);
      }
    });
  } else {
    array.filter((data, idx) => {
      if (data._uid === block._uid) {
        props.setComponents([
          ...array,
          { ...(array[idx + 1].conditional = true) },
        ]);
      }
    });
  }
};

export const saveChanges = (props, block) => {
  props.arrayComponent.filter((data, idx) => {
    if (data._uid === block._uid) {
      props.setComponents([...props.arrayComponent]);
    }
  });
};

export const backComponent = (props, block, oo) => {
  let array =
    oo === 0 || oo === undefined
      ? props.arrayComponentInitial
      : props.arrayComponentServices;

  if (
    props.arrayComponentInitial.length === 0 &&
    props.arrayComponentServices === undefined
  ) {
    const index = props.arrayComponent.findIndex(
      (data) => data._uid === block._uid
    );

    if (index !== -1) {
      props.arrayComponent.map((data, idx) => {
        if (idx > index) {
          props.setComponents([
            ...props.arrayComponent,
            { ...(props.arrayComponent[idx].conditional = false) },
          ]);
        }
      });
    }
  } else {
    const index = array.findIndex((data) => data._uid === block._uid);
    if (index !== -1) {
      array.map((data, idx) => {
        if (idx > index) {
          props.setComponents([
            ...array,
            { ...(array[idx].conditional = false) },
          ]);
        }
      });
    }
  }
};

export const setFormComponent = (props, block, objData) => {
  if (props.formData.length !== 0) {
    let objIndex = props.formData.findIndex(
      (obj) => Object.keys(obj)[0] === block.component
    );
    if (objIndex === -1) {
      props.setForm([...props.formData, objData]);
    } else {
      props.formData[objIndex][block.component] = Object.values(objData)[0];
    }
  } else {
    props.setForm([...props.formData, objData]);
  }
};
export const setFormComponentInfo = (props, block, objData) => {
  if (props.formDataInfo.length !== 0) {
    let objIndex = props.formDataInfo.findIndex(
      (obj) => Object.keys(obj)[0] === block.component
    );
    if (objIndex === -1) {
      props.setFormInfo([...props.formDataInfo, objData]);
    } else {
      props.formDataInfo[objIndex][block.component] = Object.values(objData)[0];
    }
  } else {
    props.setFormInfo([...props.formDataInfo, objData]);
  }
};
export const clearFormData = (props, block) => {
  if (props.formData.length !== 0) {
    let objIndex = props.formData.findIndex(
      (obj) => Object.keys(obj)[0] === block.component
    );
    // if (objIndex !== -1) {
    //   props.setForm([...props.formData.splice(0, objIndex + 1)]);
    // }
    if (props.NotImages) {
      if (objIndex !== -1) {
        props.setForm([...props.formData.splice(0, objIndex + 1)]);
      }
    } else {
      if (objIndex !== -1) {
        props.setForm([...props.formData.splice(0, objIndex)]);
      }
    }
  }
};
export const clearFormDataInfo = (props, block) => {
  if (props.formDataInfo.length !== 0) {
    let objIndex = props.formDataInfo.findIndex(
      (obj) => Object.keys(obj)[0] === block.component
    );
    if (objIndex !== -1) {
      props.setFormInfo([...props.formDataInfo.splice(0, objIndex + 1)]);
    }
  }
};

export const setFormComponentsArray = (props, block, objData) => {
  if (props.formData.length !== 0) {
    let objIndex = props.data.findIndex(
      (obj) => Object.keys(obj)[0] === block.component
    );
    if (objIndex === -1) {
      props.setForm([...props.formData, objData]);
    } else {
      props.formData[objIndex][block.component] = Object.values(objData)[0];
    }
  } else {
    props.setForm([...props.formData, objData]);
  }
};
export const resetFormData = (formData, notImages,
  setForm) => {
   let block = {
    _uid: "BUY6Drn1e1",
    component: "welcome",
    headline: "Welcome",
    conditional: false,
  };
  if (formData.length !== 0) {
    let objIndex = formData.findIndex(
      (obj) => Object.keys(obj)[0] === block.component
    );
    // if (objIndex !== -1) {
    //   props.setForm([...props.formData.splice(0, objIndex + 1)]);
    // }
    if (notImages) {
      if (objIndex !== -1) {
        setForm([...formData.splice(0, objIndex + 1)]);
      }
    } else {
      if (objIndex !== -1) {
        setForm([...formData.splice(0, objIndex)]);
      }
    }
  }
};
export const backComponent2 = (arrayComponentInitial, arrayComponent, setComponents) => {
  let block = {
    _uid: "BUY6Drn1e1",
    component: "welcome",
    headline: "Welcome",
    conditional: true,
  };
  const index = arrayComponent.findIndex(
    (data) => data._uid === block._uid
  );

  if (index !== -1) {
    arrayComponent.map((data, idx) => {
      if (idx > index) {
        setComponents([
          ...arrayComponent,
          { ...(arrayComponent[idx].conditional = false) },
        ]);
      }
    });
  }
  // arrayComponent.map((data, idx) => {

  //   setComponents([
  //     ...arrayComponent,
  //     { ...(arrayComponent[idx].conditional = false) },
  //   ]);

  // });


};
export const resetNextComponent = (arrayComponentInitial, arrayComponent, setComponents) => {

  let block = {
    _uid: "BUY6Drn1e1",
    component: "welcome",
    headline: "Welcome",
    conditional: false,
  };
  arrayComponentInitial.filter((data, idx) => {
    if (data._uid === block._uid) {
      setComponents([
        ...arrayComponentInitial,
        { ...(arrayComponentInitial[idx + 1].conditional = true) },
      ]);
    }
  });

};