import { useTranslation } from "react-i18next";
import imgSizeTattooTidy from "../../../../assets/images/img-Size-Tattoo-Tidy.png";
import imgSizeTattooSmall from "../../../../assets/images/img-Size-Tattoo-Small.png";
import imgSizeTattooMedium from "../../../../assets/images/img-Size-Tattoo-Medium.png";
import imgSizeTattooMediumNew from "../../../../assets/images/img-Size-Tattoo-Medium-New.png";
import imgSizeTattooLarge from "../../../../assets/images/img-Size-Tattoo-Large.png";
import imgSizeTattooExtraLarge from "../../../../assets/images/img-Size-Tattoo-Extra-Large.png";
// import imageTattooIDontKnow from "../../../../assets/images/image-Tattoo-I-Dont-Know.png";
import dontknowImage from "../../../../assets/images/dontknow.png";

export const SizeGeneric = () => {
  const [t] = useTranslation("global");

  return [
    {
      id: "no-lo-se-size",
      text: `${t("colorData.colorIDoNotKnow")}`,
      textSize: `${t("colorData.colorIDoNotKnow")}`,
      value: "No lo sé",
      image: dontknowImage,
      type: "radio",
    },
    {
      id: "diminuto",
      text: `${t("sizeData.sizeTiny")}`,
      textSize: `${t("sizeData.textSizeTyny")}`,
      value: "Menor de 4 cm",
      image: imgSizeTattooTidy,
      type: "radio",
    },
    {
      id: "pequeño",
      text: `${t("sizeData.sizeSmall")}`,
      textSize: `${t("sizeData.textSizeSmall")}`,
      value: "de 5 a 9 cm",
      image: imgSizeTattooSmall,
      type: "radio",
    },
    {
      id: "mediano",
      text: `${t("sizeData.sizeMedium")}`,
      textSize: `${t("sizeData.textSizeMedium")}`,
      value: "de 10 a 15 cm",
      image: imgSizeTattooMedium,
      type: "radio",
    },
    {
      id: "grande",
      text: `${t("sizeData.sizeLarge")}`,
      textSize: `${t("sizeData.textSizeLarge")}`,
      value: "de 16 a 20 cm",
      image: imgSizeTattooLarge,
      type: "radio",
    },
    {
      id: "muy-grande",
      text: `${t("sizeData.sizeExtraLarge")}`,
      textSize: `${t("sizeData.textSizeExtraLarge")}`,
      value: "Mayor de 20 cm",
      image: imgSizeTattooExtraLarge,
      type: "radio",
    },
  ];
};
export const SizeMicro = () => {
  const [t] = useTranslation("global");

  return [
    {
      id: "no-lo-se-size",
      text: `${t("colorData.colorIDoNotKnow")}`,
      textSize: `${t("colorData.colorIDoNotKnow")}`,
      value: "No lo sé",
      image: dontknowImage,
      type: "radio",
    },
    {
      id: "diminuto",
      text: `${t("sizeData.sizeTiny")}`,
      textSize: `${t("sizeData.textSizeTyny")}`,
      value: "Menor de 4 cm",
      image: imgSizeTattooTidy,
      type: "radio",
    },
    {
      id: "pequeño",
      text: `${t("sizeData.sizeSmall")}`,
      textSize: `${t("sizeData.textSizeSmallMicro")}`,
      value: "de 5 a 7 cm",
      image: imgSizeTattooSmall,
      type: "radio",
    },
    {
      id: "mediano",
      text: `${t("sizeData.sizeMedium")}`,
      textSize: `${t("sizeData.textSizeMediumMicro")}`,
      value: "de 8 a 10 cm",
      image: imgSizeTattooMediumNew,
      type: "radio",
    },
    {
      id: "intermedio",
      text: `${t("sizeData.sizeIntermediateMicro")}`,
      textSize: `${t("sizeData.textSizeIntermediateMicro")}`,
      value: "de 11 a 15 cm",
      image: imgSizeTattooMedium,
      type: "radio",
    },
    {
      id: "grande",
      text: `${t("sizeData.sizeLarge")}`,
      textSize: `${t("sizeData.textSizeLarge")}`,
      value: "de 16 a 20 cm",
      image: imgSizeTattooLarge,
      type: "radio",
    },
    {
      id: "muy-grande",
      text: `${t("sizeData.sizeExtraLarge")}`,
      textSize: `${t("sizeData.textSizeExtraLarge")}`,
      value: "Mayor de 20 cm",
      image: imgSizeTattooExtraLarge,
      type: "radio",
    },
  ];
};
