import { textMessageWhatsApp } from "./textMessage/textMessageWhatsApp";

export const WhatsappMessage = (
  phoneUser,
  phoneServices,
  formData,
  textMessageServices,
  user,
  t,
  optionButtonServices
) => {
  // const phone = phoneCity === null ? user.phone : phoneCity;
  let text;
  let phone;

  
  if (optionButtonServices === "tatuaje" || optionButtonServices === null) {
  
    phone = phoneUser;
    text = textMessageWhatsApp(formData, user, t);
  } else {
     phone = phoneServices;
    text = textMessageServices;
  }
  let whatsapp = `https://api.whatsapp.com/send?phone=+${phone}&text=${text}`;
  window.open(`${whatsapp}`, "_blank");
};
