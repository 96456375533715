import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../../MessageLoading/MessageLoading";

export const Text = ({ props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(true);
  const [messageLoading2, setMessageLoading2] = useState(true);
  let userIdRol = props.user.id_rol;

  useEffect(() => {
    props.setShowWriting(true);
    setTimeout(() => {
      setMessageLoading(false);
    }, 1000);
    setTimeout(() => {
      setMessageLoading2(false);
      props.setShowWriting(false);
    }, 2000);
  }, []);

  const keyMapping = {
    name_budget: t("info.name"),
    date_budget: t("info.date"),
    size: t("info.size"),
    body_part: t("info.bodyPart"),
    budget_color: t("info.color"),
    styles: t("info.styles"),
    description: t("info.description"),
    email_budget: "Email: ",
    phone_budget: t("info.phone"),
    check_city: t("info.city"),
    time_availability: t("info.schedule"),
    check_skintone: t("info.skinTone"),
    check_employeds: t("info.artist"),
    images: t("info.images"),
    type_jewelry: t("infoPiercing.jewelryType"),
    old_client: t("infoPiercing.oldClient"),
  };
  return (
    <>
      {messageLoading && <MessageLoading />}
      {!messageLoading && (
        <div className="divGenericLabelFormChat" autoFocus>
          <Form.Label
            className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer"
            autoFocus
          >
            {userIdRol !== 4
              ? `${t("info.text")} 👇`
              : `${t("info.textPiercing")}`}
          </Form.Label>
        </div>
      )}
      {!messageLoading2 && (
        <div className="divGenericLabelFormChat">
          <div className="LabelInfoTattooFormChat backgroundInputVar">
            <p className="mb-0 textBoldFormChat">{t("info.select")}</p>
            {props.formDataInfo.map((objeto, index) => {
              const [formDataValue, dataValue] = Object.entries(objeto)[0];
              if (dataValue) {
                if (
                  formDataValue === "id_user" ||
                  formDataValue === "budget_type"
                ) {
                  return null;
                }
                const key = keyMapping[formDataValue];
                if (formDataValue === "check_skintone") {
                  return (
                    <>
                      <br />
                      <strong>{key}</strong>
                      <div className="imageContainer">
                        <img src={dataValue} alt="Skin Tone" />
                      </div>
                    </>
                  );
                }
                return (
                  <div key={index}>
                    {dataValue.length > 0 && (
                      <>
                        <br />
                        <strong>{key}</strong>
                        {formDataValue === "images" ? (
                          <div className="imagesInfo">
                            {dataValue.map((imageUrl, index) => (
                              <div key={index} className="imageContainer">
                                <img src={imageUrl} alt={`Imagen ${index}`} />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <>{dataValue}</>
                        )}
                      </>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      )}
    </>
  );
};
