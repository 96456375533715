export const textMessage = (formData, user, t) => {
  const {
    name_budget,
    description,
    size,
    body_part,
    budget_color,
    email_budget,
    phone_budget,
    check_city,
    time_availability,
    check_skintone,
    check_employeds,
    images,
    type_jewelry,
    old_client,
    date_budget
  } = formData.reduce((result, obj) => {
    const [key, value] = Object.entries(obj)[0];
    result[key] = value;
    return result;
  }, {});

  let text = "";
  let modifiedUrls = "";

  if (images && images.length !== 0) {
    const modifiedUrls2 =
      images &&
      images
        .map((url, index) => {
          const modifiedUrl = decodeURIComponent(
            url.replace("?alt", "%3Falt").replace(/=media/g, "%3Dmedia")
          );
          return `${modifiedUrl}%0a`;
        })
        .join("%0a\n");
    modifiedUrls = `%0a%0a<strong>${t(
      "SendWhatsAppForm.images"
    )}</strong>%0a${modifiedUrls2}`;
  }

  const keyMapping = {
    size: `<strong>${t("SendWhatsAppForm.size")}</strong> ${size}`,
    body_part: `<strong>${t(
      "SendWhatsAppForm.bodyPart"
    )}</strong> ${body_part}`,
    budget_color: `<strong>${t(
      "SendWhatsAppForm.colorIn1"
    )}</strong> ${budget_color}`,
    email_budget: `${t("SendWhatsAppForm.email")} ${email_budget}`,
    date_budget: `${t("date.date")} ${date_budget}`,
    phone_budget: `${t("SendWhatsAppForm.phone")} ${phone_budget}`,
    check_city: `<strong>${t("SendWhatsAppForm.city")}</strong> ${check_city}`,
    time_availability: `<strong>${t(
      "SendWhatsAppForm.time"
    )}</strong> ${time_availability}`,
    check_skintone: `<strong>${t(
      "SendWhatsAppForm.skinTone"
    )}</strong> ${check_skintone}`,
    check_employeds: `<strong>${t(
      "SendWhatsAppForm.artist"
    )}</strong> ${check_employeds}`,
    type_jewelry: `<strong>${t(
      "SendWhatsAppForm.typeJewelry"
    )}</strong> ${type_jewelry}`,
    old_client: `<strong>${t(
      "SendWhatsAppForm.oldClient"
    )}</strong> ${old_client}`,
  };

  if (user && user.studio_name) {
    text = `${t("SendWhatsAppForm.hello")}<strong>${
      user.studio_name
    }</strong>!%0a%0a`;
  }

  if (name_budget) {
    text += `${t(
      "SendWhatsAppForm.myNameIs"
    )} <strong>${name_budget}</strong> ${t("SendWhatsAppForm.next")}%0a%0a`;
  }

  if (description) {
    text += `${
      description === "" || description === undefined
        ? ""
        : `<strong>${t(
            "SendWhatsAppForm.description"
          )}</strong> ${description}%0a`
    }`;
  }

  text += formData
    .filter((item) =>
      Object.keys(item).every((key) => keyMapping[key] !== undefined)
    )
    .map((item) => {
      return Object.keys(item)
        .map((key) => `${keyMapping[key]} `)
        .join("\n");
    })
    .join("%0a");
  return text + modifiedUrls;
};
