import React from "react";
import "./icon-plus.css";

export const IconPlus = () => {
  return (
    <div className="divIconPlus">
      <div className="iconPlus">
        <div className="plus"></div>
      </div>
    </div>
  );
};
