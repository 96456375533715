import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import logoTypeWhite from "../../assets/images/logo-Type-White.svg";
import logoNobleArt from "../../assets/imagesLogo/nobleArtLogo/NobleArtLogo.svg";
import logoNev from "../../assets/imagesLogo/nevLogo/nevtattooLogo.png";
// import LogoTiny from "../../assets/imagesLogo/tinytattoo/tinytattoo.jpg";
import LogoSv from "../../assets/imagesLogo/svTattoo/svTattoo.png";
import LogoTiny from "../../assets/imagesLogo/tinytattoo/tinytattoo2-transformed.jpeg";
import LogoLucvstattoo from "../../assets/imagesLogo/lucvstattoo/lucvstattoo.png";
import "./PageLoading.css";
import "./PageLoading.css";


export const PageLoading = ({ setLoading, user }) => {
  const { text } = useParams();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  const chooseImage = () => {
    //escribir en los case siempre en minuscula
    switch (text.toLowerCase()) {
      case "noble-art":
        return logoNobleArt;
      case "matias-noble":
        return logoNobleArt;
      case "nev":
        return logoNev;
      case "tiny-tattoo":
        return LogoTiny;
      case "lucvs-tattoo":
        return LogoLucvstattoo;
        case "sv-tattoo-estudio-":
          return LogoSv;
      default:
        return logoTypeWhite;
    }
  };
  return (
    <div className={text.toLowerCase() === "tiny-tattoo" ? "divPageLoadingTiny" : "divPageLoading"}  >
      <img
        className="logotypePageLoading"
        src={chooseImage()}
        alt="Icono Inkup"
      ></img>
      <div>
        <Spinner animation="border colorSpinner mt-5" role="status">
          <span className=" visually-hidden "></span>
        </Spinner>
      </div>
    </div>
  );
};
