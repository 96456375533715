import React from "react";
import "./icon-whatsapp.css";
export const IconWhatsApp = () => {
  return (
    <div className="genericWhatsapp">
      <div className="iconWhatsapp">
        <div className="divWhatsapp">
          <div className="triangleWhatsapp"></div>
          <div className="phoneWhatsapp">
            <div className=""></div>
          </div>
        </div>
      </div>
    </div>
  );
};
