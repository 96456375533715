import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { DotsComponents } from "../DotsComponent/DotsComponents";
import { ColorData, ColorTattooDataBlack } from "./ColorData";
import {
  nextComponent,
  setFormComponent,
  setFormComponentInfo,
} from "../../utils/nextComponent";

export const Color = ({ block, props }) => {
  const { user, styles } = props;
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(null);
  const [dot, setDot] = useState(0);
  const myRef2 = useRef(null);
  let colorDataArray =
    user.color_options === 0 || user.color_options === null
      ? ColorData()
      : ColorTattooDataBlack();

  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
      props.setShowWriting(false);
      executeScroll();
    }, 1000);
    props.setShowWriting(true);
    setMessageLoading(true);
    props.setColor("");
  }, []);

  const handleColor = (value, text, i) => {
    props.setColor(value);
    setDot(i);
    setFormComponent(props, block, { budget_color: value });
    setFormComponentInfo(props, block, { budget_color: text });
    nextComponent(props, block);
  };

  const executeScroll = () => {
    setTimeout(() => {
      myRef2?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  useEffect(() => {
    let colorInfoFinally;
    let colorInfo = colorDataArray?.filter(
      (data) => data?.value === props?.budget_color && data.text
    );
    colorInfoFinally = colorInfo[0]?.text;
    setFormComponentInfo(props, block, { budget_color: colorInfoFinally });
  }, [props.language]);

  return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <>
          <Form.Group id="">
            <div className="divGenericLabelFormChat" ref={myRef2}>
              <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
                {t("color.text1")}
                <span className="textBoldFormChat">{t("color.text2")}</span>
              </Form.Label>
            </div>
          </Form.Group>
          <Form.Group className="pFC">
            <div id="idColorFormChat" className="divGenericBodyPartFormChat">
              <div className="divScrollBodypart">
                {colorDataArray.map((data, index) => (
                  <div key={data.id}>
                    <Form.Check type={data.type} id={`check-api-${data.id}`}>
                      <div
                        className={
                          data?.image
                            ? "divBodyPartFormChat"
                            : "divBodyPartFormChat backgroundCarrousel"
                        }
                        style={{
                          backgroundImage: styles?.color_filter
                            ? `linear-gradient(${styles?.color_filter}, ${styles?.color_filter}), url(${data.image})`
                            : `  url(${data.image})`,
                          borderRadius: "11px",
                        }}
                      >
                        <Form.Check.Input
                          className="inputRadioFormChat"
                          type={data.type}
                          isValid
                          checked={props.budget_color === data.value}
                          onChange={(e) =>
                            handleColor(data.value, data.text, index)
                          }
                        />
                        <Form.Check.Label className="divTextDefinitionLabelImage"></Form.Check.Label>
                      </div>
                      <div className="divTextBodyPartPiercing">
                        <p
                          className={
                            props?.styles?.color_text_carousel !== null
                              ? "colorTextVarCarousel m-0"
                              : "colorTextVar m-0"
                          }
                        >
                          {data.text}
                        </p>
                      </div>
                    </Form.Check>
                  </div>
                ))}
              </div>
            </div>
          </Form.Group>
          <DotsComponents dotNumber={colorDataArray.length} iDot={dot} />
        </>
      )}
    </>
  );
};
