import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { saveBudget, saveBudget2, saveAnotherQuery } from "../../../helpers/helpers";

export const TermsAndConditions = ({ props }) => {
  const [t] = useTranslation("global");
  const handleSaveBudget = async () => {

    props.setCheckTerms(true);
    let data = props.formData;

    if (props.checkTerms === false) {
      await saveBudget({
        id_user: props.user.id_user,
        budget_type: props.user.id_rol === 4 ? 2 : 1,
        formData: data,
      });
    }
  };

  const handleSaveAnotherQuery = (e) => {
    props.setCheckTerms(true);
    let query = props.query;
    let id_user = props.user.id_user;
    if (props.checkTerms === false) {
      props.setCheckTermsAnotherQuery(true);
      saveAnotherQuery({ id_user, query });
    }
  };

  return (
    <Form.Group id="" className="mt-5">
      <Form.Group
        className="divTermsFormChat paddingButtonCheck"
        controlId="formBasicCheckbox"
      >
        <Form.Check.Input
          id="idCheckTermsFormChat"
          className="inputRadioFormChat sizeInputTermsFormChat2"
          type="checkBox"
          onChange={
            props.query.length === 0 && props.checkTerms === false
              ? (props.optionButtonServices === 'tatuaje') ? saveBudget2 : handleSaveBudget
              : handleSaveAnotherQuery
          }
          checked={props.checkTerms}
          readOnly
        />
        <span className="pTerminosFormChat2 ">
          {t("signup.accept")} {t("signup.terms")}
        </span>
      </Form.Group>
    </Form.Group>
  );
};
