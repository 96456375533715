import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import TemplateRoute from "../../components/index";
import { Container } from "react-bootstrap";
import { Error404PageNotFound } from "../../components/ErrorPageNotFound/Error404PageNotFound";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "../../i18n/es/global.json";
import global_en from "../../i18n/en/global.json";
import global_de from "../../i18n/de/global.json";
 
export default function PublicRoute() {
  const [language, setLanguage] = useState(window.navigator.language);
  const [errorAlert, setErrorAlert] = useState(undefined);
  useEffect(() => {
    switch (language?.slice(0, 2)) {
      case "es":
        setLanguage("es");
        break;
      case "de":
        setLanguage("de");
        break;
      default:
        setLanguage("en");
        break;
    }
  }, [language]);

  i18next.init({
    interpolation: { escapeValue: false },
    lng: language,
    resources: {
      es: {
        global: global_es,
      },
      en: {
        global: global_en,
      },
      de: {
        global: global_de,
      },
    },
  });

  return (
    <I18nextProvider i18n={i18next}>
      <Container fluid className="p-0 chat-form-chat backgroundContainer">
        <Routes>
          <Route
            exact
            path="/:text"
            element={
              <TemplateRoute language={language} setLanguage={setLanguage} errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
            }
            
          />
           <Route
            exact
            path="/reload/:text/:data"
            element={
              <TemplateRoute language={language} setLanguage={setLanguage} error={true} errorAlert={errorAlert} setErrorAlert={setErrorAlert} />
            }
          />
          <Route exact path="/*" element={<Error404PageNotFound />} />
        </Routes>
      </Container>
    </I18nextProvider>
  );
}
