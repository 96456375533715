import React from "react";
import { useTranslation } from "react-i18next";

import Alert from 'react-bootstrap/Alert';

export const AlertError = ({ errorAlert, setErrorAlert }) => {
  const [t] = useTranslation("global");

  React.useEffect(() => {
    if (errorAlert === true) {
      setErrorAlert(true);
      const timeoutId = setTimeout(() => {
        setErrorAlert(undefined);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [errorAlert]);
  return (
    <>
      {errorAlert && (
        <Alert className="alertError">
          {t("alertError.message")}
        </Alert>
      )}
    </>
  );
};
