import React from "react";
import "./icon-info.css";

export const IconInfo = () => {
  return (
    <div className="divIconInfo">
      <div className="iconInfo">
        <div className="infoIcon"></div>
      </div>
    </div>
  );
};
