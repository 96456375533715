import { useTranslation } from "react-i18next";
import imageTattooColor from "../../../../assets/images/image-Tattoo-Color.png";
import imageTattooNegro from "../../../../assets/images/image-Tattoo-Negro.png";
// import imageTattooIDontKnow from "../../../../assets/images/image-Tattoo-I-Dont-Know.png";
import dontknowImage from "../../../../assets/images/dontknow.png";

export const ColorData = () => {
  const [t] = useTranslation("global");

  return [
    {
      id: "no-lo-se-color",
      text: `${t("colorData.colorIDoNotKnow")}`,
      value: "No lo sé",
      image: dontknowImage,
      type: "radio",
    },
    {
      id: "color",
      text: `${t("colorData.colorColor")}`,
      value: "Color",
      image: imageTattooColor,
      type: "radio",
    },
    {
      id: "negro",
      text: `${t("colorData.colorBlack")}`,
      value: "Negro",
      image: imageTattooNegro,
      type: "radio",
    },
  ];
};

export const ColorTattooDataBlack = () => {
  const [t] = useTranslation("global");

  return [
    {
      id: "negro",
      text: `${t("colorData.colorBlack")}`,
      value: "Negro",
      image: imageTattooNegro,
      type: "radio",
    },
  ];
};
