export const textMessageWhatsApp = (formData, user, t) => {
  const {
    name_budget,
    description,
    size,
    body_part,
    budget_color,
    email_budget,
    phone_budget,
    check_city,
    time_availability,
    check_skintone,
    check_employeds,
    images,
    type_jewelry,
    old_client, date_budget
  } = formData.reduce((result, obj) => {
    const [key, value] = Object.entries(obj)[0];
    result[key] = value;
    return result;
  }, {});

  let text = "";
  let modifiedUrls = "";
  if (images && images.length !== 0) {
    const modifiedUrls2 =
      images &&
      images
        .map((url, index) => {
          return `${url}%0a`;
        })
        .join("%0a\n");
    modifiedUrls = `%0a%0a*${t("SendWhatsAppForm.images")}* ${modifiedUrls2}`;
  }

  const keyMapping = {
    size: `*${t("SendWhatsAppForm.size")}* ${size}`,
    body_part: `*${t("SendWhatsAppForm.bodyPart")}* ${body_part}`,
    budget_color: `*${t("SendWhatsAppForm.colorIn1")}* ${budget_color}`,
    email_budget: `*${t("SendWhatsAppForm.email")}* ${email_budget}`,
    date_budget: `*${t("date.date")}* ${date_budget}`,
    phone_budget: `${t("SendWhatsAppForm.phone")} ${phone_budget}`,
    check_city: `*${t("SendWhatsAppForm.city")}* ${check_city}`,
    time_availability: `*${t("SendWhatsAppForm.time")}* ${time_availability}`,
    check_skintone: `*${t("SendWhatsAppForm.skinTone")}* ${check_skintone}`,
    check_employeds: `*${t("SendWhatsAppForm.artist")}* ${check_employeds}`,
    type_jewelry: `*${t("SendWhatsAppForm.typeJewelry")}* ${type_jewelry}`,
    old_client: `*${t("SendWhatsAppForm.oldClient")}* ${old_client}`,
  };

  if (user && user.studio_name) {
    text = `${t("SendWhatsAppForm.hello")}*${user.studio_name}!*%0a%0a`;
  }

  if (name_budget) {
    text += `${t("SendWhatsAppForm.myNameIs")} *${name_budget}* ${t(
      "SendWhatsAppForm.next"
    )}%0a%0a`;
  }

  if (description) {
    text += `${description === "" || description === undefined
        ? ""
        : `*${t("SendWhatsAppForm.description")}* ${description}%0a`
      }`;
  }

  text += formData
    .filter((item) =>
      Object.keys(item).every((key) => keyMapping[key] !== undefined)
    )
    .map((item) => {
      return Object.keys(item)
        .map((key) => `${keyMapping[key]}`)
        .join("\n");
    })
    .join("%0a");
  return text + modifiedUrls;
};
