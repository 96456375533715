import React, { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import "./MessageLoading.css";

export const MessageLoading = ({ scrollRef }) => {
  const myRef2 = useRef(null);

  useEffect(() => {
    executeScroll();
  }, []);

  const executeScroll = () => {
    setTimeout(() => {
      myRef2?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  return (
    <Form.Group id="nombre" className="">
      {scrollRef === false ? (
        <div className="divGenericLabelFormChat colorDotVar">
          <div className="message loading new">
            <span></span>
          </div>
        </div>
      ) : (
        // <div className="divGenericLabelFormChat colorDotVar" ref={myRef2}>
        <div className="divGenericLabelFormChat colorDotVar" >
          <div className="message loading new">
            <span></span>
          </div>
        </div>
      )}
    </Form.Group>
  );
};
