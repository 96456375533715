import React from "react";
import { BrowserRouter } from "react-router-dom";
import PublicRoute from "./routes/public/index";

const App = () => {
  return (
    <BrowserRouter>
      <PublicRoute />
    </BrowserRouter>
  );
};

export default App;
