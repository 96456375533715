import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { nextComponent } from "../../utils/nextComponent";

export const Welcome = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(true);

  useEffect(() => {
    props.setShowWriting(true);
    setTimeout(() => {
      setMessageLoading(false);
      nextComponent(props, block);
    }, 1000);
  }, []);
   return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <>
          {props.welcomeMessage === null ? (
            <div className="divGenericLabelFormChatWelcome marginBottom8px">
              <Form.Label className="genericLabelFormChat borderColorLabelVar mt-3 genericLabelFormChatBubble backgroundColorContainer">
                {t("welcome.welcome")}
                <span className="textBoldFormChat">
                  {props?.user.name}
                  {t("welcome.welcome1")}
                  {props?.user.studio_name}
                </span>
                {props.arrayComponentServices !== undefined &&
                //si tienes en chats_components servicios rellenados pinta estas dos traducciones
                  props.arrayComponentServices?.length !== 0
                  //tempalte 5 es para tiny
                  ? (props.user.id_template === 5 ? t("welcome.whatsApp") : t("welcome.welcome2"))
                  //si no lo tiene coge estas dos
                  : props.user.check_tattoo === 1
                    ? t("welcome.whatsApp")
                    : t("welcome.email")}
              </Form.Label>
            </div>
          ) :
            (
              <div className="divGenericLabelFormChatWelcome marginBottom8px">
                <Form.Label className="genericLabelFormChat borderColorLabelVar mt-3 genericLabelFormChatBubble backgroundColorContainer">
                  {props.welcomeMessage}
                </Form.Label>
              </div>
            )}

        </>
      )}
    </>
  );
};
