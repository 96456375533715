import React from "react";
import "./icon-arrow-rigth.css";
export const IconArrowRight = () => {
  return (
    <div className="divIconArrowRight">
      <div className="iconArrow">
        <div className="arrow-right"></div>
      </div>
    </div>
  );
};
